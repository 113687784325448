import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout>
      <SEO title={frontmatter.title} description={frontmatter.excerpt} />
      <article>
        <section>
          <h1
            style={{
              margin: `0`,
              textDecoration: `none`,
            }}
          >
            <span
              style={{
                color: `white`,
                background: `#303430`,
                padding: `0 0.5rem`,
              }}
            >
              {frontmatter.title}
            </span>
          </h1>
          <p
            style={{
              fontSize: "0.75rem",
            }}
          >
            Published{" "}
            <time style={{ fontVariant: "small-caps" }}>
              {frontmatter.date}
            </time>
          </p>
          <p className="blog-post-excerpt">{frontmatter.excerpt}</p>
        </section>
        {frontmatter.cover && (
          <section
            style={{
              marginBottom: `1rem`,
            }}
          >
            <Img
              fluid={frontmatter.cover.childImageSharp.fluid}
              height={400}
              cover={true}
            />
          </section>
        )}
        <section
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        excerpt
        cover {
          childImageSharp {
            fluid(maxWidth: 800, maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
